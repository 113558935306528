<template>
  <div>
    <ui-header :heading="$tc('models.department', 2)">
      <auth-checker
        :accessRoles="['SuperAdmin', 'CompanyAdmin', 'BuildingAdmin']"
      >
        <ui-link class="add_new_button" type="text" :route="{ name: 'departments-create' }">{{
          `${$tc('actions.add_new', 1)} ${$tc('models.department')}`
        }}</ui-link>
      </auth-checker>
    </ui-header>

    <department-list :departments="departments" v-loading="loading">
    </department-list>
  </div>
</template>

<script>
import UiHeader from '@/components/ui/UiHeader';
import UiLink from '@/components/ui/UiLink';
import DepartmentList from '../components/DepartmentList';
import { getDepartments } from '../api';
import AuthChecker from '../../auth/components/AuthChecker';

export default {
  data() {
    return {
      departments: [],
      loading: false
    };
  },

  components: {
    UiHeader,
    UiLink,
    DepartmentList,
    AuthChecker
  },

  methods: {
    async getDepartments() {
      this.loading = true;
      try {
        const departments = await getDepartments();
        if (departments.length > 0) {
          this.departments = departments.sort((a, b) =>
            a.name > b.name ? 1 : -1
          );
        }
        this.loading = false;
      } catch (error) {
        this.$message({ message: this.$t('errors.general'), type: 'error' });
        this.loading = false;
      }
    }
  },

  created() {
    this.getDepartments();
  },

  mounted() {
    this.$store.commit('changeBreadcrumbs', [
      {name: "models.departments", link: "/app/departments/"}
    ]);
  },
};
</script>

<style scoped>
.add_new_button {
  background-color: white;
  border: solid 1px black;
  color: black;
}
</style>